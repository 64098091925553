require('./bootstrap');

require('jquery');
require('leaflet');



// mapa


$(document).ready(function(){

    $('.mapa').each(function(index) {

        var map = L.map($(this).attr('id'), ).setView(
            [$(this).data('lat'), $(this).data('lon')],
            $(this).data('zoom')
        );



        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            // attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20,
            minZoom: 12,


        }).addTo(map);


        L.marker([$(this).data('lat'), $(this).data('lon')]).addTo(map);


    });
});



